.custom-button {
  color: white !important; /* Hover text color */
  border-radius: 4px; /* Optional for styling */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.custom-button:hover {
  background-color: white; /* Initial background color */
  color: teal !important; /* Initial text color */
}

.custom-quill .ql-editor img {
  display: inline-block;
}
